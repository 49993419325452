/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.1.4 --types --shadows interface.glb
*/

// Modelled in blender and exported with gltfjsx

import { Html, useGLTF } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import React, { useEffect, useRef } from "react"
import * as THREE from "three"
import {
	Clock,
	MathUtils,
	Mesh,
	MeshStandardMaterial,
	Vector4,
	WebGLRenderTarget,
} from "three"
import ThreeCustomShaderMaterial from "three-custom-shader-material"
import { GLTF } from "three-stdlib"
import useMidiStore from "../stores/midi"
import { chladniMapping } from "../utils/noteMapping"
import Adsr from "./adsr"
import chladniFragmentShader from "./chladniFragmentShader"
import SoundwaveScene from "./soundwaveScene"

const interpolateRotary = (value: number) => {
	return MathUtils.mapLinear(
		value,
		1,
		0,
		-Math.PI / 2 - Math.PI / 4,
		Math.PI / 2 + Math.PI / 4
	)
}

type GLTFResult = GLTF & {
	nodes: {
		Plane007: THREE.Mesh
		chladni_frame001: THREE.Mesh
		Plane013: THREE.Mesh
		Plane017: THREE.Mesh
		Plane018: THREE.Mesh
		Plane019: THREE.Mesh
		red: THREE.Mesh
		chladni_screen001: THREE.Mesh
		model_screen: THREE.Mesh
		timeline_screen: THREE.Mesh
		adsr_screen: THREE.Mesh
		pause: THREE.Mesh
		play: THREE.Mesh
		record: THREE.Mesh
		info: THREE.Mesh
		Cube013: THREE.Mesh
		white: THREE.Mesh
		blue: THREE.Mesh
		green: THREE.Mesh
	}
	materials: {
		["Material.004"]: THREE.MeshStandardMaterial
		["Material.005"]: THREE.MeshStandardMaterial
		["Material.006"]: THREE.MeshStandardMaterial
		["Material.003"]: THREE.MeshStandardMaterial
		Material: THREE.MeshStandardMaterial
		["Material.002"]: THREE.MeshStandardMaterial
	}
}

export function Model(
	props: JSX.IntrinsicElements["group"] & { renderTexture: WebGLRenderTarget }
) {
	const chladniShaderMaterial = useRef<typeof ThreeCustomShaderMaterial>(null)
	const chladniClock = useRef(new Clock(false))

	const knobBlue = useRef<Mesh>(null)
	const knobGreen = useRef<Mesh>(null)
	const knobWhite = useRef<Mesh>(null)
	const knobRed = useRef<Mesh>(null)

	useEffect(() =>
		useMidiStore.subscribe((state) => {
			if (chladniShaderMaterial.current) {
				const lastNote = state.notes[state.notes.length - 1]
				const newChladniParams = chladniMapping(lastNote)

				if (lastNote !== state.lastNote && lastNote !== undefined) {
					const oldChladniParams = chladniMapping(state.lastNote || 69)

					// @ts-ignore
					chladniShaderMaterial.current.uniforms.chladniParametersB.value.set(
						oldChladniParams.x,
						oldChladniParams.y,
						oldChladniParams.z,
						oldChladniParams.w
					)

					// @ts-ignore
					chladniShaderMaterial.current.uniforms.chladniParametersA.value.set(
						newChladniParams.x,
						newChladniParams.y,
						newChladniParams.z,
						newChladniParams.w
					)

					chladniClock.current.stop()
					chladniClock.current.start()

					state.lastNote = lastNote
				}
			}
		})
	)

	useEffect(() =>
		useMidiStore.subscribe((state) => {
			if (knobBlue.current) {
				knobBlue.current.rotation.y = interpolateRotary(state.knobBlue)

				// @ts-ignore
				chladniShaderMaterial.current.uniforms.scaleX.value = state.knobBlue
			}
		})
	)

	useEffect(() =>
		useMidiStore.subscribe((state) => {
			if (knobGreen.current) {
				knobGreen.current.rotation.y = interpolateRotary(state.knobGreen)

				// @ts-ignore
				chladniShaderMaterial.current.uniforms.scaleY.value = state.knobGreen
			}
		})
	)

	useEffect(() =>
		useMidiStore.subscribe((state) => {
			if (knobWhite.current) {
				knobWhite.current.rotation.y = interpolateRotary(state.knobWhite)

				// @ts-ignore
				chladniShaderMaterial.current.uniforms.thickness.value = state.knobWhite
			}
		})
	)

	useEffect(() =>
		useMidiStore.subscribe((state) => {
			if (knobRed.current) {
				knobRed.current.rotation.y = interpolateRotary(state.knobRed)

				// @ts-ignore
				chladniShaderMaterial.current.uniforms.speed.value = state.knobRed
			}
		})
	)

	useFrame(() => {
		if (chladniShaderMaterial.current) {
			// @ts-ignore
			chladniShaderMaterial.current.uniforms.u_time.value =
				chladniClock.current.getElapsedTime() / 10
		}
	})

	// @ts-ignore
	const { nodes, materials } = useGLTF("/interface.glb") as GLTFResult
	return (
		<group {...props} dispose={null}>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Plane007.geometry}
				material={materials["Material.004"]}
				position={[-0.4, -0.5, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.chladni_frame001.geometry}
				material={materials["Material.004"]}
				position={[-1.1, 0.5, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Plane013.geometry}
				material={materials["Material.004"]}
				position={[1.1, 0, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Plane017.geometry}
				material={materials["Material.004"]}
				position={[-0.45, 0.8, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Plane018.geometry}
				material={materials["Material.004"]}
				position={[0, 0.25, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Plane019.geometry}
				material={materials["Material.004"]}
				position={[-1.5, -0.5, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.red.geometry}
				material={materials["Material.005"]}
				position={[0.44, 0.78, 0.04]}
				rotation={[Math.PI / 2, 0, 0]}
				ref={knobRed}
				scale={0.15}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.chladni_screen001.geometry}
				position={[-1.1, 0.5, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			>
				<ThreeCustomShaderMaterial
					ref={chladniShaderMaterial}
					baseMaterial={MeshStandardMaterial}
					vertexShader={`
				out vec2 vUv;
				void main() {
					vUv = uv;
				}
				`}
					fragmentShader={chladniFragmentShader}
					roughness={0.1}
					color={0xff00ff}
					uniforms={{
						chladniParametersA: { value: new Vector4(2, 3, 4, 5) },
						chladniParametersB: { value: new Vector4(2, 3, 4, 5) },
						u_time: { value: 0 },
						scaleX: { value: 1 },
						scaleY: { value: 1 },
						thickness: { value: 0.5 },
						speed: { value: 0.5 },
					}}
				/>
			</mesh>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.model_screen.geometry}
				position={[1.1, 0, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			>
				<meshStandardMaterial
					attach="material"
					map={props.renderTexture.texture}
				/>
			</mesh>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.timeline_screen.geometry}
				position={[-0.4, -0.5, 0]}
				rotation={[Math.PI / 2, 0, 0]}
			>
				<SoundwaveScene />
				<meshStandardMaterial color="black" metalness={1} roughness={0} />
			</mesh>
			<Html
				position={[0, 0.25, -0.05]}
				transform
				occlude
				receiveShadow
				castShadow
				material={<meshStandardMaterial roughness={0.1} color={0xff00ff} />}
				pointerEvents="none"
				prepend
			>
				<div
					className="bg-black/50 pointer-events-none rounded-lg"
					style={{ width: "440px", height: "160px", transform: "scale(0.1)" }}
				>
					<Adsr width={440} height={160} />
				</div>
			</Html>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.pause.geometry}
				material={materials["Material.006"]}
				position={[-1.5, -0.25, 0.01]}
				scale={[1, 1, 0.2]}
			>
				<Html
					position={[0, 0, 0.1]}
					transform
					occlude
					receiveShadow
					castShadow
					prepend
					pointerEvents="none"
				>
					<div
						className="flex items-center justify-center"
						style={{ width: "40px", height: "40px", transform: "scale(0.1)" }}
					>
						<svg
							width="20px"
							height="20px"
							version="1.1"
							viewBox="0 0 1200 1200"
							xmlns="http://www.w3.org/2000/svg"
							fill="purple"
						>
							<g>
								<path d="m427.2 30h-267.6c-25.199 0-46.801 20.398-46.801 46.801v1047.6c0 25.199 20.398 46.801 46.801 46.801h266.4c25.199 0 46.801-20.398 46.801-46.801v-1047.6c0-26.402-20.402-46.801-45.602-46.801z" />
								<path d="m1040.4 30h-267.6c-25.199 0-46.801 20.398-46.801 46.801v1047.6c0 25.199 20.398 46.801 46.801 46.801h266.4c25.199 0 46.801-20.398 46.801-46.801v-1047.6c0-26.402-20.398-46.801-45.602-46.801z" />
							</g>{" "}
						</svg>
					</div>
				</Html>
			</mesh>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.play.geometry}
				material={materials["Material.006"]}
				position={[-1.5, -0.1, 0.01]}
				scale={[1, 1, 0.2]}
				onClick={() => {
					console.log("aksdjflkasd")
				}}
			>
				<Html
					position={[0, 0, 0.1]}
					transform
					occlude
					receiveShadow
					castShadow
					prepend
					pointerEvents="none"
				>
					<div
						className="flex items-center justify-center"
						style={{ width: "40px", height: "40px", transform: "scale(0.1)" }}
					>
						<svg
							width="20px"
							height="20px"
							version="1.1"
							viewBox="0 0 1200 1200"
							xmlns="http://www.w3.org/2000/svg"
							fill="purple"
						>
							<path d="m1010.4 523.2-717.6-477.6c-61.199-40.801-144 3.6016-144 76.801v954c0 74.398 82.801 117.6 144 76.801l717.6-477.6c53.996-34.801 53.996-116.4-0.003906-152.4z" />
						</svg>
					</div>
				</Html>
			</mesh>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.record.geometry}
				material={materials["Material.006"]}
				position={[-1.5, -0.4, 0.01]}
				scale={[1, 1, 0.2]}
				onClick={() => {
					console.log("aksdjflkasd")
				}}
			>
				<Html
					position={[0, 0, 0.1]}
					transform
					occlude
					receiveShadow
					castShadow
					prepend
					pointerEvents="none"
				>
					<div
						className="flex items-center justify-center"
						style={{ width: "40px", height: "40px", transform: "scale(0.1)" }}
					>
						<svg
							width="20px"
							height="20px"
							version="1.1"
							viewBox="0 0 1200 1200"
							xmlns="http://www.w3.org/2000/svg"
							fill="purple"
						>
							<path d="m600 30c-314.4 0-570 255.6-570 570s255.6 570 570 570 570-255.6 570-570-255.6-570-570-570zm0 787.2c-120 0-217.2-97.199-217.2-217.2s97.199-217.2 217.2-217.2 217.2 97.199 217.2 217.2-97.199 217.2-217.2 217.2z" />
						</svg>
					</div>
				</Html>
			</mesh>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.info.geometry}
				material={materials["Material.006"]}
				position={[-1.5, -0.8, 0.01]}
				scale={[1, 1, 0.2]}
			>
				<Html
					position={[0, 0, 0.1]}
					transform
					occlude
					receiveShadow
					castShadow
					prepend
					pointerEvents="none"
				>
					<div
						className="flex items-center justify-center"
						style={{ width: "40px", height: "40px", transform: "scale(0.1)" }}
					>
						<svg
							width="20px"
							height="20px"
							version="1.1"
							viewBox="0 0 1200 1200"
							xmlns="http://www.w3.org/2000/svg"
							fill="purple"
						>
							<g>
								<path d="m598.8 1068c50.398 0 92.398-42 92.398-92.398v-439.2c0-50.398-42-92.398-92.398-92.398-50.398 0-92.398 42-92.398 92.398v439.2c-0.003906 50.402 41.996 92.402 92.398 92.402z" />
								<path d="m598.8 374.4c50.398 0 92.398-42 92.398-92.398v-69.602c0-50.398-42-92.398-92.398-92.398-50.398 0-92.398 42-92.398 92.398v69.602c-0.003906 50.398 41.996 92.398 92.398 92.398z" />
							</g>{" "}
						</svg>
					</div>
				</Html>
			</mesh>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.Cube013.geometry}
				material={materials["Material.004"]}
				position={[0, 0, -0.2]}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.white.geometry}
				material={materials["Material.003"]}
				position={[0.14, 0.78, 0.04]}
				rotation={[Math.PI / 2, 0, 0]}
				ref={knobWhite}
				scale={0.15}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.blue.geometry}
				material={materials.Material}
				position={[-0.46, 0.78, 0.04]}
				ref={knobBlue}
				rotation={[Math.PI / 2, 0, 0]}
				scale={0.15}
			/>
			<mesh
				castShadow
				receiveShadow
				geometry={nodes.green.geometry}
				material={materials["Material.002"]}
				position={[-0.16, 0.78, 0.04]}
				rotation={[Math.PI / 2, 0, 0]}
				ref={knobGreen}
				scale={0.15}
			/>
		</group>
	)
}

useGLTF.preload("/interface.glb")
