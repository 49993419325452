import { enableMapSet } from "immer"
import React from "react"
import ReactDOM from "react-dom/client"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import App from "./App"
import Geometry from "./Geometry"
import "./index.css"

enableMapSet()

const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
	},
	{ path: "/export", element: <Geometry /> },
])

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
)
