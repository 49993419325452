import React from "react"
import useMidiStore from "../stores/midi"

const Adsr = ({ width, height }: { width: number; height: number }) => {
	const attack = useMidiStore((state) => state.knobBlue)
	const decay = useMidiStore((state) => state.knobGreen)
	const sustain = useMidiStore((state) => state.knobWhite)
	const release = useMidiStore((state) => state.knobRed)

	const padding = 20

	const aStartX = width * 0.25 * attack + padding
	const bEndX = aStartX + width * 0.25 * decay
	const cHeight = (height - padding * 2) * (1 - sustain) + padding
	const dStartX = width - (1 - release) * width * 0.25 - padding

	const color = "white"

	return (
		<svg width={width} height={height}>
			<path
				d={`M${padding} ${
					height - padding
				} Q${padding} 30 ${aStartX} ${padding}  M${aStartX} ${padding} Q${aStartX} ${cHeight} ${bEndX} ${cHeight}  ${bEndX},${cHeight} ${dStartX},${cHeight}  M${dStartX} ${cHeight} Q${dStartX} ${
					height - padding
				} ${width - padding} ${height - padding}`}
				strokeWidth={2}
				stroke={color}
				fill="none"
			/>

			<circle cx={aStartX} cy={padding} r={5} fill={color} />
			<circle cx={bEndX} cy={cHeight} r={5} fill={color} />
			<circle cx={dStartX} cy={cHeight} r={5} fill={color} />
			<circle cx={width - padding} cy={height - padding} r={5} fill={color} />
			<circle cx={padding} cy={height - padding} r={5} fill={color} />
		</svg>
	)
}

export default Adsr
