// Inspired by https://thelig.ht/chladni/

const chladniFragmentShader = `
in vec2 vUv;
uniform vec4 chladniParametersA;
uniform vec4 chladniParametersB;
uniform float u_time;

uniform float scaleX;
uniform float scaleY;
uniform float thickness;
uniform float speed;

float sampleDigital(vec2 inP) {
	vec2 p = inP * 2. - 1.;
	p *= vec2(mix(0.25, 1.5, scaleX), mix(0.25, 1.5, scaleY));

	float time = clamp(u_time * 8., 0., 1.1);
	vec4 current = mix(chladniParametersB, chladniParametersA, time);

	float a = current.x;
	float n = current.y;
	float m = current.z;
	float b = current.a;
			
	float max_amp = abs(a) + abs(b);
	float amp = a * sin(PI*n*p.x) * sin(PI*m*p.y) + b * sin(PI*m*p.x) * sin(PI*n*p.y);

	float col = step(abs(amp), mix(.3, 1.3, abs(thickness - .5) * 2.));
	if (thickness < 0.5) {
		col = 1. - col;
	}
	return col;
}

void main(){
	float color = sampleDigital(vUv);
	csm_DiffuseColor = vec4(vec3(sampleDigital(vUv)), 1.0);
}
`

export default chladniFragmentShader
