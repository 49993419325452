import { useFrame } from "@react-three/fiber"
import React, { useEffect, useRef } from "react"
import { Mesh, PerspectiveCamera, Vector4 } from "three"
import useMidiStore from "../stores/midi"
import { chladniMapping } from "../utils/noteMapping"
import { SDfMaterialProps } from "./sdfMaterial"

import { SdfMaterial } from "./sdfMaterial"

console.log(SdfMaterial)

const SdfScene = ({ camera }: { camera: PerspectiveCamera | undefined }) => {
	const mesh = useRef<Mesh>(null)
	const material = useRef<SDfMaterialProps>(null)

	useFrame(({ gl }) => {
		if (material.current && camera?.position !== undefined) {
			if (material.current.uniforms.cameraPosition !== undefined) {
				const newPos = camera.position
				// @ts-ignore
				material.current.uniforms.cameraPosition.value.x = newPos.x
				// @ts-ignore
				material.current.uniforms.cameraPosition.value.y = newPos.y
				// @ts-ignore
				material.current.uniforms.cameraPosition.value.z = newPos.z
			}
		}

		if (mesh.current) {
			mesh.current.rotation.y += 0.002
			// gl.properties.remove(gl.properties.get(mesh.current.material))
		}
	})

	useEffect(() =>
		useMidiStore.subscribe((state) => {
			if (material.current) {
				// @ts-ignore
				material.current.uniforms.mode.value = state.notes.length

				if (state.notes.length > 0) {
					const mapping = chladniMapping(state.notes[0]).clone()
					// @ts-ignore
					material.current.uniforms.noteOne.value = mapping
				} else {
					// @ts-ignore
					material.current.uniforms.noteOne.value = new Vector4(0, 0, 0, 0)
				}

				if (state.notes.length > 1) {
					const mapping = chladniMapping(state.notes[1]).clone()
					// @ts-ignore
					material.current.uniforms.noteTwo.value = mapping
				} else {
					// @ts-ignore
					material.current.uniforms.noteTwo.value = new Vector4(0, 0, 0, 0)
				}

				if (state.notes.length > 2) {
					const mapping = chladniMapping(state.notes[2]).clone()
					// @ts-ignore
					material.current.uniforms.noteThree.value = mapping
				} else {
					// @ts-ignore
					material.current.uniforms.noteThree.value = new Vector4(0, 0, 0, 0)
				}

				if (state.notes.length > 3) {
					const mapping = chladniMapping(state.notes[3]).clone()
					// @ts-ignore
					material.current.uniforms.noteFour.value = mapping
				} else {
					// @ts-ignore
					material.current.uniforms.noteFour.value = new Vector4(0, 0, 0, 0)
				}

				if (state.notes.length > 4) {
					const mapping = chladniMapping(state.notes[4]).clone()
					// @ts-ignore
					material.current.uniforms.noteFive.value = mapping
				} else {
					// @ts-ignore
					material.current.uniforms.noteFive.value = new Vector4(0, 0, 0, 0)
				}

				if (state.notes.length > 5) {
					const mapping = chladniMapping(state.notes[5]).clone()
					// @ts-ignore
					material.current.uniforms.noteSix.value = mapping
				} else {
					// @ts-ignore
					material.current.uniforms.noteSix.value = new Vector4(0, 0, 0, 0)
				}

				if (state.notes.length > 6) {
					const mapping = chladniMapping(state.notes[6]).clone()
					// @ts-ignore
					material.current.uniforms.noteSeven.value = mapping
				} else {
					// @ts-ignore
					material.current.uniforms.noteSeven.value = new Vector4(0, 0, 0, 0)
				}

				// @ts-ignore
				material.current.uniformsNeedUpdate = true
				// @ts-ignore
				material.current.needsUpdate = true
			}
		})
	)

	return (
		<>
			<mesh scale={1} ref={mesh}>
				<boxGeometry args={[1, 2, 1]} />

				<sdfMaterial ref={material} key={1} />
			</mesh>
		</>
	)
}

export default SdfScene
