import { extend, ReactThreeFiber } from "@react-three/fiber"
import { Vector3, Vector4 } from "three"
import { rawShaderMaterial } from "./rawShaderMaterial"
import fragmentShader from "./sdfFragmentShader"

export interface SDfMaterialProps {
	uniforms: {
		cameraPosition: Vector3
		mode: number
		noteOne: Vector4
		noteTwo: Vector4
		noteThree: Vector4
		noteFour: Vector4
		noteFive: Vector4
	}
}

declare global {
	namespace JSX {
		interface IntrinsicElements {
			sdfMaterial: ReactThreeFiber.Object3DNode<
				SDfMaterialProps,
				typeof SdfMaterial
			>
		}
	}
}

const vertexShader = /* glsl */ `
in vec3 position;
uniform mat4 modelMatrix;
uniform mat4 modelViewMatrix;
uniform mat4 projectionMatrix;
uniform vec3 cameraPosition;
out vec3 vOrigin;
out vec3 vDirection;
void main() {
	vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
	vOrigin = vec3( inverse( modelMatrix ) * vec4( cameraPosition, 1.0 ) ).xyz;
	vDirection = position - vOrigin;
	gl_Position = projectionMatrix * mvPosition;
}
`

const SdfMaterial = rawShaderMaterial(
	{
		u_resolution: [1024, 1024],
		cameraPosition: new Vector3(0, 0, 0),
		mode: 0,
		noteOne: new Vector4(0, 0, 0, 0),
		noteTwo: new Vector4(1, 0, 0, 0),
		noteThree: new Vector4(1, 0, 0, 0),
		noteFour: new Vector4(1, 0, 0, 0),
		noteFive: new Vector4(1, 0, 0, 0),
		noteSix: new Vector4(1, 0, 0, 0),
		noteSeven: new Vector4(1, 0, 0, 0),
		scale: 2,
		thickness: 1,
	},
	vertexShader,
	fragmentShader
)

extend({ SdfMaterial })

export { SdfMaterial }
