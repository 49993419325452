export interface Wave {
	frequency: number
	amplitude: number
}

export const soundwave = (waves: Wave[], x: number) => {
	let y = 0
	waves.forEach((wave) => {
		y += wave.amplitude * Math.sin(wave.frequency * Math.PI * x)
	})
	return y
}

export const amplitude = (
	waves: Wave[],
	x: number,
	y: number,
	rnorm: number,
	weight: number
) => {
	let z = 0
	waves.forEach((wave) => {
		z +=
			Math.sin(wave.frequency * Math.PI * x) *
			Math.sin(wave.frequency * Math.PI * y) *
			wave.amplitude
	})
	return z * rnorm * 0.4 * weight
}
