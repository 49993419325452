import { MathUtils, Vector4 } from "three"

export enum Device {
	OP1 = "op1",
	Keyboard = "keyboard",
}

export const noteMappingForDevice = (device: Device, key: number) => {
	switch (device) {
		case Device.OP1:
			return key
		case Device.Keyboard:
			switch (key) {
				case 65:
					return 53
				case 87:
					return 54
				case 83:
					return 55
				case 69:
					return 56
				case 68:
					return 57
				case 82:
					return 58
				case 70:
					return 59
				case 71:
					return 60
				case 84:
					return 61
				case 72:
					return 62
				case 85:
					return 63
				case 74:
					return 64
				case 75:
					return 65
				case 73:
					return 66
				case 76:
					return 67
				case 79:
					return 68
				case 186:
					return 69
				default:
					return 69
			}
	}
}

export const chladniMapping = (key: number) => {
	return new Vector4(
		MathUtils.mapLinear(key, 50, 70, 0, 10),
		MathUtils.mapLinear(key, 70, 50, 5, 10),
		MathUtils.mapLinear(key, 50, 70, 3, 10),
		MathUtils.mapLinear(key, 70, 50, 0, 10)
	)
}
