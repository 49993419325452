// completly reimplemented, but based on angular code by https://www.toptal.com/web/creating-browser-based-audio-applications-controlled-by-midi-hardware

export class Synthesizer {
	private audioContext: AudioContext
	private oscillator: Oscillator
	private amp: Amp
	private filter: Filter
	private activeNotes = new Set<number>()
	private currentFrequency: undefined | number = undefined

	constructor(audioContext: AudioContext) {
		this.audioContext = audioContext

		this.amp = new Amp(this.audioContext)

		this.oscillator = new Oscillator(this.audioContext)
		this.oscillator.setType("square")

		this.filter = new Filter(this.audioContext)
		this.filter.setFrequency(500)
		this.filter.setResonance(0)

		this.oscillator.connect(this.amp.gainNode)
		this.amp.connect(this.filter.filterNode)
		this.filter.connect(this.audioContext.destination)

		this.amp.setVolume(0, 0)
		this.oscillator.start()
	}

	private keyToFrequency(note: number) {
		return 440 * Math.pow(2, (note - 69) / 12)
	}

	private velocity(velocity: number) {
		return parseFloat((velocity / 127).toFixed(2))
	}

	noteOn = (
		note: number,
		velocity: number,
		attack: number,
		portamento: number
	) => {
		this.activeNotes.add(note)

		this.oscillator.cancel()
		this.currentFrequency = this.keyToFrequency(note)
		this.oscillator.setFrequency(this.currentFrequency, portamento)

		this.amp.cancel()

		this.amp.setVolume(this.velocity(velocity), attack)
	}

	noteOff = (note: number, release: number) => {
		this.activeNotes.delete(note)

		if (this.activeNotes.size === 0) {
			this.amp.cancel()
			this.currentFrequency = undefined
			this.amp.setVolume(0, 0.05)
		} else {
			this.oscillator.cancel()
			this.currentFrequency = this.keyToFrequency(
				Array.from(this.activeNotes)[0]
			)
			this.oscillator.setFrequency(this.currentFrequency, release)
		}
	}
}

class Oscillator {
	readonly oscillatorNode: OscillatorNode
	constructor(audioContext: AudioContext) {
		this.oscillatorNode = audioContext.createOscillator()
	}

	setType = (type: OscillatorType) => {
		this.oscillatorNode.type = type
	}

	setFrequency = (frequency: number, time: number) => {
		this.oscillatorNode.frequency.setTargetAtTime(frequency, 0, time)
	}

	start = () => {
		this.oscillatorNode.start()
	}

	stop = () => {
		this.oscillatorNode.stop()
	}

	connect = (destination: AudioNode) => {
		this.oscillatorNode.connect(destination)
	}

	cancel = () => {
		this.oscillatorNode.frequency.cancelScheduledValues(0)
	}

	disconnect = () => {
		this.oscillatorNode.disconnect(0)
	}
}

class Amp {
	readonly gainNode: GainNode
	constructor(audioContext: AudioContext) {
		this.gainNode = audioContext.createGain()
	}

	setVolume = (volume: number, time: number) => {
		this.gainNode.gain.setTargetAtTime(volume, 0, time)
		// this.gainNode.gain.value = volume
	}

	connect = (destination: AudioNode) => {
		this.gainNode.connect(destination)
	}

	cancel = () => {
		this.gainNode.gain.cancelScheduledValues(0)
	}

	disconnect = () => {
		this.gainNode.disconnect(0)
	}
}

class Filter {
	readonly filterNode: BiquadFilterNode
	constructor(audioContext: AudioContext) {
		this.filterNode = audioContext.createBiquadFilter()
	}

	setType = (type: BiquadFilterType) => {
		this.filterNode.type = type
	}

	setFrequency = (frequency: number) => {
		this.filterNode.frequency.value = frequency
	}

	setResonance = (q: number) => {
		this.filterNode.Q.value = q
	}

	connect = (destination: AudioNode) => {
		this.filterNode.connect(destination)
	}

	cancel = () => {
		this.filterNode.frequency.cancelScheduledValues(0)
	}

	disconnect = () => {
		this.filterNode.disconnect(0)
	}
}
