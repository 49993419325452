import { useFrame } from "@react-three/fiber"
import React, { useRef } from "react"
import { Color, Group, Vector3 } from "three"
import useMidiStore from "../stores/midi"
import SoundwaveDisplay, { damp } from "./soundwaveDisplay"

const SoundwaveScene = () => {
	const notes = useMidiStore((state) => state.notes)
	const group = useRef<Group>(null)

	useFrame((state, delta) => {
		if (group.current) {
			const newPosition = damp(
				group.current.position,
				new Vector3(0, 0, -((notes.length + 1) / 5) * 0.2),
				6,
				delta
			)

			// make the x axis smaller
			group.current.position.set(newPosition.x, newPosition.y, newPosition.z)

			const newScale = damp(
				group.current.scale,
				new Vector3(1, 1, (1 / (notes.length + 1)) * 3),
				6,
				delta
			)

			// make the x axis smaller
			group.current.scale.set(newScale.x, newScale.y, newScale.z)
		}
	})

	return (
		<>
			<group ref={group}>
				<SoundwaveDisplay
					index={undefined}
					standByAnimation={notes.length > 0}
					color={new Color("white")}
					hidden={false}
				/>

				{new Array(10).fill(0).map((_, i) => {
					const color = new Color("black")
					let hidden = true
					if (i < notes.length) {
						color.setHSL(i / notes.length, 1, 0.5)
						hidden = false
					}

					return (
						<SoundwaveDisplay
							key={i}
							standByAnimation
							index={i}
							color={color}
							offset={i * 0.2 + 0.2}
							hidden={hidden}
						/>
					)
				})}
			</group>
		</>
	)
}

export default SoundwaveScene
