import create from "zustand"
import { immer } from "zustand/middleware/immer"

interface MidiStore {
	notes: number[]
	lastNote: number

	setNote: (note: number, pressed: boolean) => void

	knobBlue: number
	knobGreen: number
	knobWhite: number
	knobRed: number

	setKnob: (knob: number, value: number) => void

	isRecording: boolean
}

const useMidiStore = create(
	// immer based zustand store
	immer<MidiStore>((set, get) => ({
		// state
		notes: [],
		lastNote: -1,
		knobBlue: 0.5,
		knobGreen: 0.5,
		knobWhite: 0.5,
		knobRed: 0.5,
		isRecording: true,

		// actions
		setNote: (note, pressed) => {
			set((state) => {
				if (pressed) {
					if (!state.notes.includes(note)) {
						state.notes.push(note)
					}
				} else {
					// delete the note from the state.notes array
					const index = state.notes.indexOf(note)
					if (index > -1) {
						state.notes.splice(index, 1)
					}
				}
			})
		},

		setKnob: (knob, value) => {
			set((state) => {
				switch (knob) {
					case 0:
						state.knobBlue = value
						break
					case 1:
						state.knobGreen = value
						break
					case 2:
						state.knobWhite = value
						break
					case 3:
						state.knobRed = value
						break
				}
			})
		},
	}))
)

export default useMidiStore
