import { OrbitControls } from "@react-three/drei"
import { useFrame } from "@react-three/fiber"
import React, { useRef } from "react"
import { Mesh, MeshBasicMaterial, Vector4 } from "three"
import ThreeCustomShaderMaterial from "three-custom-shader-material"
import chladniFragmentShader from "./chladniSliceFragmentShader"
import { SDfMaterialProps } from "./sdfMaterial"

import { SdfMaterial } from "./sdfMaterial"

console.log(SdfMaterial)

const GeometryScene = ({
	start,
	end,
	thickness,
	slice,
}: {
	start: Vector4
	end: Vector4
	thickness: number
	slice: number
}) => {
	const mesh = useRef<Mesh>(null)
	const material = useRef<SDfMaterialProps>(null)
	const chladniMaterial = useRef<typeof ThreeCustomShaderMaterial>(null)

	useFrame(({ camera }) => {
		if (material.current && camera?.position !== undefined) {
			if (material.current.uniforms.cameraPosition !== undefined) {
				const newPos = camera.position
				// @ts-ignore
				material.current.uniforms.cameraPosition.value.x = newPos.x
				// @ts-ignore
				material.current.uniforms.cameraPosition.value.y = newPos.y
				// @ts-ignore
				material.current.uniforms.cameraPosition.value.z = newPos.z
			}

			// @ts-ignore
			material.current.uniforms.mode.value = 2

			// @ts-ignore
			material.current.uniforms.scale.value = 1

			// @ts-ignore
			material.current.uniforms.noteOne.value = start ?? new Vector4(0, 0, 0, 0)

			// @ts-ignore
			material.current.uniforms.noteTwo.value = end ?? new Vector4(0, 0, 0, 0)

			// @ts-ignore
			material.current.uniforms.thickness.value = thickness ?? 1
		}

		if (chladniMaterial.current) {
			// @ts-ignore
			chladniMaterial.current.uniforms.chladniParametersA.value =
				start ?? new Vector4(0, 0, 0, 0)

			// @ts-ignore
			chladniMaterial.current.uniforms.chladniParametersB.value =
				end ?? new Vector4(0, 0, 0, 0)

			// @ts-ignore
			chladniMaterial.current.uniforms.thickness.value = thickness ?? 1

			console.log(slice)
			// @ts-ignore
			chladniMaterial.current.uniforms.slice.value = slice ?? 1
		}
	})

	return (
		<>
			<OrbitControls enableDamping={false} />

			<mesh scale={1} ref={mesh}>
				<boxGeometry args={[1, 1, 1]} />

				<sdfMaterial ref={material} key={1} />
			</mesh>

			<mesh position={[2, 0, 0]} rotation={[-Math.PI / 2, 0, Math.PI / 2]}>
				<planeGeometry args={[1, 1, 1]} attach="geometry" />

				<ThreeCustomShaderMaterial
					ref={chladniMaterial}
					baseMaterial={MeshBasicMaterial}
					vertexShader={`
				out vec2 vUv;
				void main() {
					vUv = uv;
				}
				`}
					fragmentShader={chladniFragmentShader}
					uniforms={{
						chladniParametersA: { value: new Vector4(0, 0, 0, 0) },
						chladniParametersB: { value: new Vector4(0, 0, 0, 0) },
						slice: { value: 0.5 },
						thickness: { value: 0.5 },
					}}
					color={0xff00ff}
				/>
			</mesh>
		</>
	)
}

export default GeometryScene
