import {
	Environment,
	OrbitControls,
	PerspectiveCamera,
} from "@react-three/drei"
import { createPortal, useFrame } from "@react-three/fiber"
import React, { useMemo, useRef } from "react"
import {
	Color,
	PerspectiveCamera as ThreeCamera,
	RGBAFormat,
	Scene,
	WebGLRenderTarget,
} from "three"
import { Model } from "./Interface"
import { SdfMaterial } from "./sdfMaterial"
import SdfScene from "./sdfScene"

console.log(SdfMaterial)

const InterfaceScene = () => {
	const cam = useRef<ThreeCamera>()
	const [scene, target] = useMemo(() => {
		const scene = new Scene()
		scene.background = new Color("orange")
		const target = new WebGLRenderTarget(1024, 1024, {
			format: RGBAFormat,
			stencilBuffer: false,
		})
		// target.samples = 8
		return [scene, target]
	}, [])

	useFrame((state) => {
		if (cam.current) {
			state.gl.setRenderTarget(target)
			state.gl.render(scene, cam.current)
			state.gl.setRenderTarget(null)
		}
	})

	return (
		<>
			<OrbitControls />
			<Model renderTexture={target} />

			<Environment preset="sunset" blur={0.8} near={0.1} far={10000} />

			<directionalLight
				shadow-mapSize-width={2048}
				shadow-mapSize-height={2048}
				shadow-bias={-0.0001}
				castShadow
				intensity={0.6}
				position={[0, 10, 10]}
			/>

			<PerspectiveCamera ref={cam} fov={40} position={[0, 0, 3.5]} />
			{createPortal(<SdfScene camera={cam.current} />, scene)}
		</>
	)
}

export default InterfaceScene
