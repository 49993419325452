// Help by: https://thelig.ht/chladni/ for the sampleDigital function,
// https://iquilezles.org/articles/distfunctions/ for a general overview and the calcNormal function as well as the soft shadows
// general help by theejs examples, mainly: https://github.com/mrdoob/three.js/blob/master/examples/webgl2_volume_perlin.html

const sdfFragmentShader = `
precision highp float;

in vec3 vOrigin;
in vec3 vDirection;

float steps = 1000.;

uniform vec4 noteOne;
uniform vec4 noteTwo;
uniform vec4 noteThree;
uniform vec4 noteFour;
uniform vec4 noteFive;
uniform vec4 noteSix;
uniform vec4 noteSeven;

uniform int mode;
uniform float scale;
uniform float thickness;

out vec4 color;

vec2 hitBox( vec3 orig, vec3 dir ) {
	vec3 box_min = vec3( - 0.5,-.5*scale, -.5 );
	vec3 box_max = vec3( 0.5,.5*scale,.5 );
	vec3 inv_dir = 1.0 / dir;
	vec3 tmin_tmp = ( box_min - orig ) * inv_dir;
	vec3 tmax_tmp = ( box_max - orig ) * inv_dir;
	vec3 tmin = min( tmin_tmp, tmax_tmp );
	vec3 tmax = max( tmin_tmp, tmax_tmp );
	float t0 = max( tmin.x, max( tmin.y, tmin.z ) );
	float t1 = min( tmax.x, min( tmax.y, tmax.z ) );
	return vec2( t0, t1 );
}

const float PI = 3.14159265;
float sampleDigital(vec3 inP) {
	vec2 p = inP.xz * 2.;
	// p.x *= 1.5;

	float t = inP.y * 2.;
	// float t = inP.y * 2.;

	float a = .0, b = .0, n = .0, m = .0;

	if (mode == 1) {
		a = noteOne.x;
		b = noteOne.y;
		n = noteOne.z;
		m = noteOne.w;
	} else if (mode == 2) {
		a = mix(noteOne.x, noteTwo.x, t);
		b = mix(noteOne.y, noteTwo.y, t);
		n = mix(noteOne.z, noteTwo.z, t);
		m = mix(noteOne.a, noteTwo.a, t);
	} else if	(mode == 3) {
		a = noteOne.x * (1.-clamp(0.5, 1., t)) + noteTwo.x * (.66-clamp(0.2, .5, t)) + noteThree.x * t;
		b = noteOne.y * (1.-clamp(0.5, 1., t)) + noteTwo.y * (.66-clamp(0.2, .5, t)) + noteThree.y * t;
		n = noteOne.z * (1.-clamp(0.5, 1., t)) + noteTwo.z * (.66-clamp(0.2, .5, t)) + noteThree.z * t;
		m = noteOne.w * (1.-clamp(0.5, 1., t)) + noteTwo.w * (.66-clamp(0.2, .5, t)) + noteThree.w * t;
	} else if	(mode == 4) {
		a = noteOne.x * (1.-clamp(0.7, 1., t)) + noteTwo.x * (.75-clamp(0.4, .7, t)) + noteThree.x * (.5-clamp(0.2, .6, t)) + noteFour.x * t;
		b = noteOne.y * (1.-clamp(0.7, 1., t)) + noteTwo.y * (.75-clamp(0.4, .7, t)) + noteThree.y * (.5-clamp(0.2, .6, t)) + noteFour.y * t;
		n = noteOne.z * (1.-clamp(0.7, 1., t)) + noteTwo.z * (.75-clamp(0.4, .7, t)) + noteThree.z * (.5-clamp(0.2, .6, t)) + noteFour.z * t;
		m = noteOne.w * (1.-clamp(0.7, 1., t)) + noteTwo.w * (.75-clamp(0.4, .7, t)) + noteThree.w * (.5-clamp(0.2, .6, t)) + noteFour.w * t;
	} else if	(mode == 5) {
		a = noteOne.x * (1.-clamp(0.75, 1., t)) + noteTwo.x * (.8-clamp(0.55, .85, t)) + noteThree.x * (.6-clamp(0.35, .65, t)) + noteFour.x * (.4-clamp(0.15, .45, t)) + noteFive.x * t;
		b = noteOne.y * (1.-clamp(0.75, 1., t)) + noteTwo.y * (.8-clamp(0.55, .85, t)) + noteThree.y * (.6-clamp(0.35, .65, t)) + noteFour.y * (.4-clamp(0.15, .45, t)) + noteFive.y * t;
		n = noteOne.z * (1.-clamp(0.75, 1., t)) + noteTwo.z * (.8-clamp(0.55, .85, t)) + noteThree.z * (.6-clamp(0.35, .65, t)) + noteFour.z * (.4-clamp(0.15, .45, t)) + noteFive.z * t;
		m = noteOne.w * (1.-clamp(0.75, 1., t)) + noteTwo.w * (.8-clamp(0.55, .85, t)) + noteThree.w * (.6-clamp(0.35, .65, t)) + noteFour.w * (.4-clamp(0.15, .45, t)) + noteFive.w * t;
	} else if (mode == 6) {
		a = noteOne.x * (1.-clamp(0.85, 1., t)) + noteTwo.x * (.83-clamp(0.6, .85, t)) + noteThree.x * (.66-clamp(0.45, .7, t)) + noteFour.x * (.5-clamp(0.3, .55, t)) + noteFive.x * (.33-clamp(0.12, .35, t)) + noteSix.x * t;
		b = noteOne.y * (1.-clamp(0.85, 1., t)) + noteTwo.y * (.83-clamp(0.6, .85, t)) + noteThree.y * (.66-clamp(0.45, .7, t)) + noteFour.y * (.5-clamp(0.3, .55, t)) + noteFive.y * (.33-clamp(0.12, .35, t)) + noteSix.y * t;
		n = noteOne.z * (1.-clamp(0.85, 1., t)) + noteTwo.z * (.83-clamp(0.6, .85, t)) + noteThree.z * (.66-clamp(0.45, .7, t)) + noteFour.z * (.5-clamp(0.3, .55, t)) + noteFive.z * (.33-clamp(0.12, .35, t)) + noteSix.z * t;
		m = noteOne.w * (1.-clamp(0.85, 1., t)) + noteTwo.w * (.83-clamp(0.6, .85, t)) + noteThree.w * (.66-clamp(0.45, .7, t)) + noteFour.w * (.5-clamp(0.3, .55, t)) + noteFive.w * (.33-clamp(0.12, .35, t)) + noteSix.w * t;
	} else if (mode == 7) {
		a = noteOne.x * (1.-clamp(0.8, 1., t)) + noteTwo.x * (.85-clamp(0.65, .9, t)) + noteThree.x * (.71-clamp(0.55, .7, t)) + noteFour.x * (.57-clamp(0.4, .55, t)) + noteFive.x * (.43-clamp(0.24, .4, t)) + noteSix.x * (.26-clamp(0.24, .12, t)) + noteSeven.x * t;
		b = noteOne.y * (1.-clamp(0.8, 1., t)) + noteTwo.y * (.85-clamp(0.65, .9, t)) + noteThree.y * (.71-clamp(0.55, .7, t)) + noteFour.y * (.57-clamp(0.4, .55, t)) + noteFive.y * (.43-clamp(0.24, .4, t)) + noteSix.y * (.26-clamp(0.24, .12, t)) + noteSeven.y * t;
		n = noteOne.z * (1.-clamp(0.8, 1., t)) + noteTwo.z * (.85-clamp(0.65, .9, t)) + noteThree.z * (.71-clamp(0.55, .7, t)) + noteFour.z * (.57-clamp(0.4, .55, t)) + noteFive.z * (.43-clamp(0.24, .4, t)) + noteSix.z * (.26-clamp(0.24, .12, t)) + noteSeven.z * t;
		m = noteOne.w * (1.-clamp(0.8, 1., t)) + noteTwo.w * (.85-clamp(0.65, .9, t)) + noteThree.w * (.71-clamp(0.55, .7, t)) + noteFour.w * (.57-clamp(0.4, .55, t)) + noteFive.w * (.43-clamp(0.24, .4, t)) + noteSix.w * (.26-clamp(0.24, .12, t)) + noteSeven.w * t;
	}
	
			
	float max_amp = abs(a) + abs(b);
	float amp = a * sin(PI*n*p.x) * sin(PI*m*p.y) + b * sin(PI*m*p.x) * sin(PI*n*p.y);

	float col = step(abs(amp), abs(thickness));
	return col;
}

vec3 calcNormal( vec3 p ) // for function f(p)
{
    const float h = 0.001; // replace by an appropriate value
    const vec2 k = vec2(1,-1);
    return normalize( k.xyy*sampleDigital( p + k.xyy*h ) + 
                      k.yyx*sampleDigital( p + k.yyx*h ) + 
                      k.yxy*sampleDigital( p + k.yxy*h ) + 
                      k.xxx*sampleDigital( p + k.xxx*h ) );
}

float calcSoftShadow(vec3 ro, vec3 rd, float tmin, float tmax, float w)
{
    // bounding volume    
    // vec2 dis = hitBox( ro, rd, vec3(1.0) ) ;
    vec2 dis = hitBox( ro, rd) ;
    if( dis.y<0.0 ) return 1.0;
    
    tmin = max(tmin,dis.x);
	tmax = min(tmax,dis.y);
    
    float t = tmin;
    float res = 1.0;
    for( int i=0; i<128; i++ )
    {
     	float h = sampleDigital(ro + t*rd);
        res = min( res, h/(w*t) );
    	t += clamp(h, 0.005, 0.50);
        if( res<-1.0 || t>tmax ) break;
    }
    res = max(res,-1.0); // clamp to [-1,1]

    return 0.25*(1.0+res)*(1.0+res)*(2.0-res); // smoothstep
}


vec3 normalColor(vec3 positionOfHit , vec3 normalOfSurface ){
	vec3 rayDir = normalize( vDirection );
	vec3 sunPosition = vec3( 5. , 10. , 3. ) - rayDir;
	
	vec3 lightDirection = sunPosition - positionOfHit;
	lightDirection = normalize( lightDirection );
	
	float faceValue = dot( lightDirection , normalOfSurface );
	vec3 bColor = vec3( .8 , 0 , .7 );
	vec3 color = mix(bColor, vec3(0.1), faceValue);
	
	return color;
}


void main(){
	vec3 rayDir = normalize( vDirection );
	vec2 bounds = hitBox( vOrigin, rayDir );
	if ( bounds.x > bounds.y ) discard;
	bounds.x = max( bounds.x, 0.0 );
	vec3 p = vOrigin + bounds.x * rayDir;
	vec3 inc = 1.0 / abs( rayDir );
	float delta = min( inc.x, min( inc.y, inc.z ) );
	delta /= steps;


	for ( float t = bounds.x; t < bounds.y; t += delta ) {

		float d = sampleDigital(p );

		bool isSmaller = d > 0.9;
		bool isLarger = d < 0.9;
		bool display = thickness > 0. ? isSmaller : isLarger;

		if ( display) {

			vec3 nor = calcNormal(p);

			const vec3 lig = normalize(vec3(1.0,0.5,0.6));
			float dif = dot(lig,nor);
			if( dif>0.0 ) dif *= calcSoftShadow(p+nor*0.001,lig,0.001,10.0,0.003);
			dif = clamp(dif,0.0,1.0);
			vec3 hal = normalize(lig);
			float spe = clamp(dot(hal,nor),0.0,1.0);
			spe = pow(spe,4.0)*dif*(0.04+0.96*pow(max(1.0-dot(hal,lig),0.0),5.0));

			vec3 col = vec3(0.0);

			vec3 mate = mix( vec3(0.1,0.3,0.1), vec3(1), d )*0.7;

			col += mate*1.5*vec3(1.30,0.85,0.75)*dif;

			color.rgb = col;
			color.rgb = normalColor(p, nor);
			// color.rgb = normal(p);
			color.a = 1.;
			break;
		}
		p += rayDir * delta;
	}
	if ( color.a == 0.0 ) discard;
}
`

export default sdfFragmentShader
