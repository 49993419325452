import { Canvas } from "@react-three/fiber"
import React, { Suspense, useEffect, useRef, useState } from "react"
import "./App.css"
import InterfaceScene from "./components/interfaceScene"
import { Synthesizer } from "./components/synth"
import useMidiStore from "./stores/midi"
import { Device, noteMappingForDevice } from "./utils/noteMapping"

function App() {
	const [showInfo, setShowInfo] = useState(true)

	const setNote = useMidiStore((state) => state.setNote)
	const setKnob = useMidiStore((state) => state.setKnob)

	const knobValues = useRef<number[]>([0, 0, 0, 0])

	useEffect(() =>
		useMidiStore.subscribe((state) => {
			knobValues.current = [
				state.knobBlue,
				state.knobGreen,
				state.knobWhite,
				state.knobRed,
			]
		})
	)

	useEffect(() => {
		const midiAccess = async () => {
			// @ts-ignore
			const midi = await navigator.requestMIDIAccess()

			for (const entry of midi.inputs) {
				const input = entry[1]
				console.log(
					`Input port [type:'${input.type}']` +
						` id:'${input.id}'` +
						` manufacturer:'${input.manufacturer}'` +
						` name:'${input.name}'` +
						` version:'${input.version}'`
				)
			}

			midi.inputs.forEach((entry: any) => {
				entry.onmidimessage = (event: any) => {
					const mappedNote = noteMappingForDevice(Device.OP1, event.data[1])

					if (event.data[0] === 176) {
						setKnob(event.data[1] - 1, event.data[2] / 127)
					} else {
						if (event.data[2] === 100) {
							synthesizer.current?.noteOn(
								mappedNote,
								8,
								knobValues.current[0],
								knobValues.current[1]
							)
						} else {
							synthesizer.current?.noteOff(mappedNote, knobValues.current[3])
						}

						setNote(mappedNote, event.data[2] === 100)
					}
				}
			})
		}

		midiAccess()
	}, [setKnob, setNote])

	const synthesizer = useRef<Synthesizer | undefined>(undefined)

	useEffect(() => {
		const onKeyDown = (e: KeyboardEvent) => {
			const mappedNote = noteMappingForDevice(Device.Keyboard, e.keyCode)
			setNote(mappedNote, true)

			synthesizer.current?.noteOn(
				mappedNote,
				8,
				knobValues.current[0],
				knobValues.current[1]
			)
		}

		const onKeyUp = (e: KeyboardEvent) => {
			const mappedNote = noteMappingForDevice(Device.Keyboard, e.keyCode)
			setNote(mappedNote, false)

			synthesizer.current?.noteOff(mappedNote, knobValues.current[3])
		}

		window.addEventListener("keydown", onKeyDown)
		window.addEventListener("keyup", onKeyUp)

		return () => {
			window.removeEventListener("keydown", onKeyDown)
			window.removeEventListener("keyup", onKeyUp)
		}
	}, [setNote])

	return (
		<div className="w-screen h-screen bg-blue-200">
			{showInfo && (
				<div className="fixed inset-0 bg-white z-[100000] flex flex-col justify-center items-center p-10 space-y-4">
					<h1 className="text-xl">
						Presss Start to create your own sound sculpture!
					</h1>
					<p>
						Use your keyboard or an attached midi controller to control the
						chladni plates!
					</p>
					<button
						className="bg-orange-500 text-white text-lg font-medium px-4 py-2 rounded-full"
						onClick={() => {
							const audioContext = new window.AudioContext()
							synthesizer.current = new Synthesizer(audioContext)

							setShowInfo(false)
						}}
					>
						Start
					</button>
				</div>
			)}

			<Canvas
				shadows="soft"
				dpr={[1, 2]}
				// camera={{ position: [-4.7, 2.32, 4.55] }}
				camera={{ position: [-0.5, 0.2, 1], zoom: 512, near: -10 }}
				// camera={{ position: [0, -1, 0], zoom: 8 }}
				orthographic
				className={showInfo ? "left-[10000px]" : ""}
			>
				<Suspense fallback={null}>
					<InterfaceScene />
				</Suspense>
			</Canvas>
		</div>
	)
}

export default App
