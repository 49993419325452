import { Canvas } from "@react-three/fiber"
import React, { Suspense, useState } from "react"
import { Vector4 } from "three"
import { useImmer } from "use-immer"
import GeometryScene from "./components/geometryScene"

const Group = ({
	label,
	point,
	setPoint,
	type = "text",
}: {
	label: string
	point: string
	setPoint: (value: string) => void
	type?: "text" | "checkbox" | "range"
}) => {
	return (
		<div className="flex flex-1 flex-row items-center rounded-lg bg-slate-700 border border-white/10 text-sm font-mono pl-1 hover:border-4 hover:border-violet-600/70 focus:border-l-violet-700 transition-all shadow-lg">
			<label
				className={`text-white/20 text-right uppercase ${
					type === "text" ? "" : "py-1"
				}`}
			>
				{label}
			</label>
			<div className={`relative flex-1 flex ${type === "range" && "px-2"}`}>
				<input
					type={type}
					className={`${type === "range" && "flex-1"} input ${
						type === "text" ? "appearance-none" : ""
					} bg-transparent text-white/70 p-1 outline-none min-w-0 w-12`}
					value={point}
					onChange={(e) => {
						setPoint(e.target.value)
					}}
					min={0}
					max={1}
					step={0.01}
				/>
			</div>
		</div>
	)
}

const Geometry = () => {
	const [start, setStart] = useImmer({ x: "1", y: "3", z: "1", w: "0.5" })
	const [end, setEnd] = useImmer({ x: "4", y: "2", z: "5", w: "2" })
	const [thickness, setThickness] = useState("1")
	const [slice, setSlice] = useState("0.0")

	return (
		<div className="h-screen w-screen bg-blue-50">
			<Canvas
				dpr={[1, 2]}
				// camera={{ position: [-4.7, 2.32, 4.55] }}
				// camera={{ position: [-0.5, 0.2, 1], zoom: 512, near: -10 }}
				// camera={{ position: [0, -1, 0], zoom: 8 }}
				// orthographic
				// className={showInfo ? "left-[10000px]" : ""}
			>
				<Suspense fallback={null}>
					<GeometryScene
						start={
							new Vector4(
								parseFloat(start.x),
								parseFloat(start.y),
								parseFloat(start.z),
								parseFloat(start.w)
							)
						}
						end={
							new Vector4(
								parseFloat(end.x),
								parseFloat(end.y),
								parseFloat(end.z),
								parseFloat(end.w)
							)
						}
						thickness={parseFloat(thickness)}
						slice={parseFloat(slice)}
					/>
				</Suspense>
			</Canvas>

			<div className="absolute top-0 right-0 p-4 space-y-1">
				<div className="flex flex-row space-x-1">
					<Group
						label="X"
						point={start.x}
						setPoint={(value) =>
							setStart((start) => {
								start.x = value
							})
						}
					/>
					<Group
						label="Y"
						point={start.y}
						setPoint={(value) =>
							setStart((start) => {
								start.y = value
							})
						}
					/>
					<Group
						label="Z"
						point={start.z}
						setPoint={(value) =>
							setStart((start) => {
								start.z = value
							})
						}
					/>
					<Group
						label="W"
						point={start.w}
						setPoint={(value) =>
							setStart((start) => {
								start.w = value
							})
						}
					/>
				</div>
				<div className="flex flex-row space-x-1">
					<Group
						label="X"
						point={end.x}
						setPoint={(value) =>
							setEnd((start) => {
								start.x = value
							})
						}
					/>
					<Group
						label="Y"
						point={end.y}
						setPoint={(value) =>
							setEnd((start) => {
								start.y = value
							})
						}
					/>
					<Group
						label="Z"
						point={end.z}
						setPoint={(value) =>
							setEnd((start) => {
								start.z = value
							})
						}
					/>
					<Group
						label="W"
						point={end.w}
						setPoint={(value) =>
							setEnd((start) => {
								start.w = value
							})
						}
					/>
				</div>

				<div className="flex flex-row space-x-1">
					<Group
						label="Thickness"
						point={thickness}
						setPoint={(value) => setThickness(value)}
					/>
					<Group
						label="Invert"
						type="checkbox"
						point={parseInt(thickness) < 0 ? "0" : "1"}
						setPoint={(_) =>
							setThickness((value) =>
								parseFloat(value) < 0
									? Math.abs(parseFloat(value)).toString()
									: (-parseFloat(value)).toString()
							)
						}
					/>
				</div>

				<Group
					label="Slice"
					type="range"
					point={slice}
					setPoint={(value) => setSlice(value)}
				/>
			</div>
		</div>
	)
}

export default Geometry
